import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import mapActions from "./actions"
import mapMutations from "./mutations"
import mapGetters from "./getters"

const BASE_URI = "/sale_stop_settings"

export const mapFiltersFromState = ({ filters: { reference, search_value } }) => ({
  reference_id: reference.id,
  search_value: search_value
})

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    reference: {},
    search_value: null
  },
  withSorting: true
})

store.mergeState({
  initialData: []
})

const { FETCH_ITEMS, UPDATE_ITEMS } = mapActions({ baseURI: BASE_URI })

store.mergeActions({ FETCH_ITEMS, UPDATE_ITEMS }, withLoading)
store.mergeMutations(mapMutations())
store.mergeGetters(mapGetters())

export default store
