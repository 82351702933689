import { api } from "@/config"
import { paginateData } from "@/config/store/StoreItemsModule/helpers"

import { orderBy, reduce, forEach, map } from "lodash-es"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { mapFiltersFromState } from "./index"
import { format } from "date-fns"

const mapResendFilters = ({ carClasses, reference, chosenOtaIds, dates }) => ({
  resend_filters: {
    car_class_ids: map(carClasses, "id"),
    reference_id: reference.id,
    start_date: format(dates[0], DATE_FNS_DATE_FORMAT),
    end_date: format(dates[1], DATE_FNS_DATE_FORMAT),
    ota_ids: chosenOtaIds
  }
})

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, dispatch, state }) => {
    dispatch("UPDATE_PAGINATION_DATA")

    const params = { filters: mapFiltersFromState(state) }
    const response = await api.get(baseURI, { params })

    commit("SET_ITEMS", response.data.data)
  },

  UPDATE_ITEMS: async ({ commit, dispatch, state }, { changedInventories }) => {
    dispatch("UPDATE_PAGINATION_DATA")

    const inventories = reduce(
      changedInventories,
      (obj, byDates, carClassId) => {
        forEach(byDates, ({ shared_inventory_limit, sale_stop, export_is_notified }, date) => {
          obj.push({
            shared_inventory_limit,
            sale_stop,
            car_class_id: Number(carClassId),
            date,
            export_is_notified
          })
        })

        return obj
      },
      []
    )

    const params = { filters: mapFiltersFromState(state), inventories }

    commit("SET_LOADING", true)
    await api.put(baseURI, params)

    dispatch("FETCH_ITEMS")
  },

  UPDATE_PAGINATION_DATA: ({ commit, state }) => {
    const { filters, pagination } = state
    const pagedData = paginateData({ data: filters.carClasses, ...pagination })

    commit("SET_CAR_CLASSES_FOR_PAGE", orderBy(pagedData.data, "order", "ASC"))
    commit("SET_PAGINATION_DATA", pagedData.pagination)
  },

  RESEND_INVENTORY: async (_, filters) => {
    const resendFilters = mapResendFilters(filters)

    await api.post(`${baseURI}/resend_inventory`, { filters: { ...resendFilters } })
  }
})
