import { bus } from "@/config"
import { mapMutations } from "vuex"

/*
 *  Confirmation dialog
 *
 *  @param {object}
 *    - title {String} title for confirmation dialog
 *    - resolve {Object} settings for resolving:
 *      • handler {Function} handler when user clicks OK
 *      • titleAfter {String} after answer title (additional info)
 *    - reject {Object} settings for rejecting:
 *      • handler {Function} handler when user clicks CANCEL
 *      • titleAfter {String} after answer title (additional info)
 *
 *  @return {undefined}
 *
 *  @example
 *    this.$confirm({
 *      title: "Are you sure to delete this user?",
 *      resolve: {
 *        handler: () => user.delete(),
 *        titleAfter: "User successfully deleted"
 *      },
 *      reject: {
 *        titleAfter: "User deletion cancelled"
 *      }
 *    })
 */
export default {
  methods: {
    ...mapMutations(["SET_SIGN_OUT_CONFIRMATION"]),

    $confirm(modalOptions) {
      bus.emit("open-confirm-dialog", modalOptions)
    },

    $conditionalConfirm({
      useConfirm,
      handler,
      component,
      cancelHandler = () => {},
      title = this.$t("actions.confirm_unsaved")
    }) {
      if (useConfirm) {
        this.$confirm({ title, component, resolve: { handler }, reject: { handler: cancelHandler } })
      } else {
        handler()
      }
    },

    setLogoutConfirm(useConfirm) {
      this.SET_SIGN_OUT_CONFIRMATION({ [this.$route.meta.title]: useConfirm })
    },

    beforeRouteLeaveHandler({ to, next, isChanges, exitHandler = () => {} }) {
      this.$conditionalConfirm({
        useConfirm: to.name !== "SignIn" && isChanges,
        handler: () => {
          exitHandler()
          next()
        },
        cancelHandler: () => window.history.forward()
      })
    }
  }
}
