// store modules
import withStoreModule from "@/mixins/withStoreModule"
import importsModule from "@/config/store/inventory_imports"
import exportsModule from "@/config/store/inventory_exports"

// misc
import { showToast } from "@/helpers/toasts"
import { flatMap, find, intersection, map } from "lodash-es"

const importsMixin = withStoreModule(importsModule, {
  name: "inventoryImports",
  readers: { imports: "items" },
  actions: { fetchImports: "FETCH_ITEMS" },
  getters: { hasImports: "hasImportsInProgress" }
})

const exportsMixin = withStoreModule(exportsModule, {
  name: "inventoryExports",
  actions: { fetchExports: "FETCH_ITEMS" },
  readers: { exports: "items" }
})

export default ({ sharedInventory = false }) => ({
  mixins: [importsMixin, exportsMixin],

  mounted() {
    this.fetchImportsAction()
    this.webSocketSubscribe("InventoryImportChannel", {
      received: this.handleInventoryImportChannelMessage
    })

    this.fetchExports()
    this.webSocketSubscribe("InventoryExportChannel", {
      received: this.handleInventoryExportChannelMessage
    })

    this.webSocketSubscribe("ProcessingReferenceChannel", {
      received: this.handleProcessingReferenceChannelMessage
    })
  },

  methods: {
    handleProcessingReferenceChannelMessage({ reference_id, processing }) {
      if (sharedInventory) {
        this.updateProcessingInventoryGroup({ inventoryGroupId: reference_id, processing })
      } else {
        this.updateProcessingShop({ shopId: reference_id, processing })
      }
    },

    handleInventoryImportChannelMessage({ import_id: importId }) {
      const importIds = map(this.imports, "id")

      if (importId && importIds.includes(importId)) {
        this.reloadInventoriesAction()
      }

      this.fetchImportsAction()
    },

    handleInventoryExportChannelMessage({ reload_exports, group_uuid }) {
      if (group_uuid && this.checkInProgressExportByGroupUUID(group_uuid)) {
        showToast({
          title: this.$t("success"),
          text: this.$t("inventory_management.inventory_export.finished"),
          variant: "success"
        })
        this.reloadInventoriesAction()
      }
      if (reload_exports) {
        this.fetchExports()
      }
    },

    checkInProgressExportByGroupUUID(groupUUID) {
      const { reference_ids } = find(this.exports, ({ export_group_uuid }) => export_group_uuid === groupUUID) || {}
      return intersection(reference_ids, this.selectedReferenceItemIds) > 0
    },

    fetchImportsAction(referenceId = this.selectedReferenceItemId) {
      if (referenceId) {
        this.fetchImports({ reference_id: referenceId })
      }
    }
  },

  computed: {
    reloadInventoriesAction() {
      if (sharedInventory) {
        return () => {
          this.fetchInventories(true)
        }
      } else {
        return this.fetchInventories
      }
    },

    selectedReferenceItemId() {
      return sharedInventory ? this.filters.inventoryGroup?.id : this.selectedShop?.id
    },

    selectedReferenceItemIds({ selectedReferenceItemId }) {
      return [selectedReferenceItemId]
    },

    isInventorySyncInProgress() {
      return this.hasImports || this.hasInProgressExports
    },

    hasInProgressExports({ inProgressReferenceItemIds, selectedReferenceItemIds }) {
      return intersection(inProgressReferenceItemIds, selectedReferenceItemIds) > 0
    },

    inProgressReferenceItemIds() {
      return flatMap(this.exports, "reference_ids")
    }
  }
})
