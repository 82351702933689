<template lang="pug">
  .available-inventory(
    :class="{ invalid: isInvalidAvailable }"
  )
    | {{ available }}
</template>

<script>
  export default {
    props: {
      sharedInventoryLimit: {
        type: Number,
        default: 0
      },
      totalBooked: [Number, String]
    },

    computed: {
      available({ sharedInventoryLimit, totalBooked }) {
        return sharedInventoryLimit - totalBooked
      },

      isInvalidAvailable({ available }) {
        return available <= 0
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .available-inventory
    +cell

    &.invalid
      color: $default-red
</style>
