<template lang="pug">
  .sale-stop-settings.car-classes.col-md-9
    Filters(
      :disabled="disabled"
      @bulk-change-active="handleBulkToggleItems($event)"
      @handle-search="$emit('handle-search', $event)"
      @save-changes="saveChanges"
      @open-bulk-modal="openBulkModal"
    )
    .car-classes-table
      .car-classes-content
        table
          thead
            tr
              th.on-off.sync.sortable(@click="handleSorting('active')")
                span {{ $t('sale_stop_settings.on_off') }}
                FaIcon.icon(
                  v-if="!disabled"
                  :icon="sortingIcon('active')"
                )
              th.car-class.sortable(@click="handleSorting('car_class_name')")
                span {{ $t('sale_stop_settings.car_class') }}
                FaIcon.icon(
                  v-if="!disabled"
                  :icon="sortingIcon('car_class_name')"
                )
              th.available-inventory-column
                span {{ $t('sale_stop_settings.available_inventory') }}
                AppTooltip(:title="$t('sale_stop_settings.available_inventory_tooltip')")
              th.otas-name
                span {{ $t('sale_stop_settings.stop_selling_ota') }}
              th.actions
          tbody
            template(v-for="(carClass, index) in $v.carClasses.$each.$iter")
              tr.matchings-item.car-class
                td.on-off
                  AppToggle(
                    :value="carClass.active.$model"
                    @change="toggleItem(index, !carClass.active.$model)"
                  )
                td
                  span {{ carClass.car_class_name.$model }}
                td(colspan="3")
                  .subsettings
                    .subsetting(
                      v-for="(setting, settingIndex) in carClass.subsettings.$each.$iter"
                      v-if="!setting.$model._deleted"
                      :key="setting.id"
                    )
                      .available_inventory
                        AppNumberInput.available-inventory(
                          :value="setting.available_inventory.$model"
                          :disabled="!carClass.active.$model"
                          :invalid="setting.available_inventory.$error"
                          @input="handleChangeSetting(index, settingIndex, 'available_inventory', $event)"
                        )
                      .otas 
                        AppDropdown.ota-name(
                          :disabled="!carClass.active.$model"
                          :class="{ invalid: setting.ota_ids.$error }"
                          close-on-select
                          searchable
                          batch-select
                          multiple
                          value-key="id"
                          title-key="name"
                          :placeholder="$t('sale_stop_settings.not_selected')"
                          :value="selectedOtas(setting.ota_ids.$model)"
                          :items="otaList"
                          :is-item-disabled="item => isItemDisabled(index, settingIndex, item)"
                          @select="handleChangeSetting(index, settingIndex, 'ota_ids', $event.map(({id}) => id).sort())"
                          order-direction="keep"
                        )
                      .actions 
                        .add 
                          AppButtonWithIcon.add-new-icon(
                            v-if="showAddIcon(index, settingIndex)"
                            :disabled="!carClass.active.$model"
                            icon="plus-circle"
                            @click="addSetting(index)"
                          )
                        .delete
                          AppButtonWithIcon.delete-icon(
                            icon="trash-alt"
                            @click="deleteSetting(index, settingIndex)"
                          )
</template>

<script>
  import { forEach, keys, isEqual, last, some } from "lodash-es"
  import { bus } from "@/config"

  // mixins
  import withSorting from "@/mixins/withSorting"
  import withValidations from "@/mixins/withValidations"
  import otaMethods from "./otaMethods"
  import withModal from "@/mixins/withModal"

  const validationsMixin = withValidations(({ required, requiredIf, minValue, integer, minLength }) => ({
    carClasses: {
      $each: {
        active: { required },
        car_class_name: { required },
        subsettings: {
          $each: {
            active: { required },
            available_inventory: {
              required: requiredIf(function(subsetting) {
                return subsetting.active
              }),
              integer,
              minValue: minValue(1)
            },
            ota_ids: {
              required: requiredIf(function(subsetting) {
                return subsetting.active
              }),
              minLength: minLength(1)
            }
          }
        }
      }
    }
  }))

  export default {
    props: {
      carClasses: {
        type: Array,
        default: () => []
      },
      otaAccounts: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      sortingData: Object
    },

    async mounted() {
      bus.on("bulk-update-sale-stop-settings", this.handleBulkChangeItems)
    },

    beforeDestroy() {
      bus.off("bulk-update-sale-stop-settings", this.handleBulkChangeItems)
    },

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      Filters: () => import("./Filters"),
      AppButtonWithIcon: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [validationsMixin, withSorting, otaMethods, withModal],

    methods: {
      handleChangeSetting(index, settingIndex, field, value) {
        this.changeSettingItem(index, settingIndex, { [field]: value })
      },

      handleBulkToggleItems(value) {
        forEach(this.carClasses, (_, index) => {
          this.toggleItem(index, value)
        })
      },

      toggleItem(index, active) {
        const item = {
          ...this.carClasses[index],
          active,
          subsettings: this.carClasses[index].subsettings.map(s => ({ ...s, active }))
        }

        this.$emit("change-item", { index, item })
      },

      changeSettingItem(index, settingIndex, items) {
        const params = keys(items).reduce((obj, key) => {
          if (items[key] !== null) obj[key] = items[key]

          return obj
        }, {})

        const newSettings = this.carClasses[index].subsettings.map((setting, i) => {
          if (i === +settingIndex) {
            return { ...setting, ...params }
          }
          return { ...setting }
        })

        this.$emit("change-item", { index, item: { ...this.carClasses[index], subsettings: newSettings } })
      },

      deleteSetting(index, settingIndex) {
        const newSettings = this.carClasses[index].subsettings.reduce((acc, ss, i) => {
          const updatedSetting = i === +settingIndex ? { ...ss, _deleted: true } : ss

          if (!updatedSetting._deleted || updatedSetting.id !== null) {
            acc.push(updatedSetting)
          }

          return acc
        }, [])

        this.$emit("change-item", { index, item: { ...this.carClasses[index], subsettings: newSettings } })

        if (newSettings.filter(({ _deleted }) => !_deleted).length === 0) {
          this.addSetting(index)
        }
      },

      addSetting(index) {
        const newSettings = [
          ...this.carClasses[index].subsettings,
          { ...this.generateEmptySetting(), active: this.carClasses[index].active }
        ]

        this.$emit("change-item", { index, item: { ...this.carClasses[index], subsettings: newSettings } })
      },

      generateEmptySetting() {
        return {
          available_inventory: null,
          ota_ids: null,
          id: null
        }
      },

      handleBulkChangeItems(params) {
        forEach(this.carClasses, (_, index) => {
          this.toggleItem(index, params.active)
          this.carClasses[index].subsettings = [
            ...this.carClasses[index].subsettings
              .filter(s => s.available_inventory && s.ota_ids)
              .map(s => ({ ...s, _deleted: true })),
            ...params.subsettings
          ]
        })
      },

      saveChanges() {
        this.validateAttributes()

        if (this.isValid) {
          this.$emit("save-changes")
          this.cancelValidation()
        }
      },

      openBulkModal() {
        this.$openModal({
          title: this.$t("sale_stop_settings.bulk_setting.title"),
          component: () => import("./BulkSettings"),
          size: "large",
          props: {
            otaAccounts: this.otaAccounts
          }
        })
      },

      showAddIcon(index, settingIndex) {
        return isEqual(
          last(this.carClasses[index].subsettings.filter(({ _deleted }) => !_deleted)),
          this.carClasses[index].subsettings[settingIndex]
        )
      },

      isItemDisabled(index, settingIndex, { id }) {
        return some(this.carClasses[index].subsettings, ({ ota_ids, _deleted }, i) =>
          settingIndex !== i && !_deleted ? ota_ids?.includes(id) : false
        )
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .car-classes
    &-table
      +matchings-table-header
      margin-top: 10px
      margin-bottom: 10px
      overflow-y: auto

      table
        width: 100%
        margin-bottom: 20px

        th
          &.on-off
            +cell-width(100px)
            width: 100px
          &.available-inventory-column
            +cell-width(100px)
            width: 100px
          &.car-class
            width: 40%
          &.otas-name
            min-width: 200px
          &.actions
            width: 76px
        tr
          vertical-align: baseline
        td
          +matchings-item-td

          ::v-deep
            .app-select
              padding-left: 0
              &.invalid
                .btn
                  +default-invalid-input
            .app-number-input
              input.field
                width: 100px
                height: 28px
              &.invalid
                +default-invalid-input


          .subsettings
            display: flex
            flex-direction: column
            .subsetting
              width: 100%
              display: flex
              align-items: center

              .available_inventory
                margin-right: 26px
                flex: 0 0 64px

                ::v-deep
                  input
                    font-weight: 400
                    font-size: 13px
                    line-height: 13px
                    width: 100%
                    text-align: right
                    padding-right: 8px
                    height: 34px


              .otas
                padding: 0 10px
                width: 100%
                min-width: 200px

              .actions
                display: flex
                align-items: center
                justify-content: space-between
                flex: 0 0 60px
                margin-left: auto

                .add-new-icon, .delete-icon
                  +icon-button($default-purple)
</style>
