import { map } from "lodash-es"

export default () => ({
  SET_IMPORTS_PROGRESS: (state, { ota_account_id, value, last_import_finished_at, finished, login_failed }) => {
    state.importsProgress = {
      ...state.importsProgress,
      [ota_account_id]: {
        value,
        lastImportFinishedAt: last_import_finished_at,
        loginFailed: login_failed
      }
    }

    state.items = map(state.items, item => {
      if (item.id === ota_account_id) {
        item.import_in_progress = !finished
        // ota account's active be will false on failed login
        if (login_failed) item.active = false
      }
      return item
    })
  }
})
