import { filter, isEqual } from "lodash-es"

export default () => ({
  itemsUpdated({ items, initialData }) {
    return filter(items, (item, index) => {
      const initialItem = initialData[index]

      return item.active != initialItem.active || !isEqual(item.subsettings, initialItem.subsettings)
    })
  }
})
