import { findIndex } from "lodash-es"

export default () => ({
  ADD_ITEM({ commit }, ota) {
    const item = { username: "", password: "", active: false, ota: ota }
    commit("ADD_ITEM", { item, prepend: true })
  },

  SET_ITEM({ commit, state }, item) {
    const index = findIndex(state.items, ({ id }) => id === item.id)
    commit("SET_ITEM_BY_INDEX", { item, index })
  },

  SET_ITEM_LOADING({ commit, state }, { itemId, loading }) {
    const index = findIndex(state.items, ({ id }) => id === itemId)
    const item = state.items[index]
    commit("SET_ITEM_BY_INDEX", { item: { ...item, loading }, index })
  },

  SET_ADDED_ITEM({ commit, state }, item) {
    const index = findIndex(state.itemsAdded, ({ _addedAt }) => _addedAt === item._addedAt)
    commit("SET_ADDED_ITEM_BY_INDEX", { item, index })
  },

  DELETE_ADDED_ITEM({ commit, state }, item) {
    const index = findIndex(state.itemsAdded, ({ _addedAt }) => _addedAt === item._addedAt)
    commit("DELETE_ADDED_ITEM_BY_INDEX", index)
  }
})
