<template lang="pug">
  .sale-stop
    .common
      AppCheckbox(
        :class="commonSaleStopClasses"
        :indeterminate="indeterminateCommonSaleStop"
        :value="commonSaleStop"
        :disabled="outdated"
        :indeterminate-icon="'hypen'"
        @change="handleChangeCommon"
        :highlight-on-hover="false"
      )
    .otas(v-if="saleStopToggler")
      .ota(
        v-for="ota in otas"
        :id="`sale-stop-${ota.id}-${carClass.id}-${day}`"
        :class="{ 'ota-inactive': isOtaInactive(ota), disabled: isMatchingsWrong(ota) }"
      )
        AppCheckbox(
          :class="{ highlight: isChanged(ota), 'purple-filled': isOtaFilled(ota) }"
          :value="saleStopByOta(ota)"
          :disabled="!isOtaActive(ota) || outdated"
          :highlight-on-hover="false"
          @change="handleChange(ota, $event)"
        )
</template>

<script>
  // components
  import { BPopover } from "bootstrap-vue"

  // misc
  import { mapState } from "vuex"
  import { some, every, isEmpty, reduce, isNil, map } from "lodash-es"

  export default {
    components: {
      BPopover,
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    props: {
      saleStop: {
        type: Object,
        default: () => new Object()
      },
      changedSaleStop: {
        type: Object,
        default: () => new Object()
      },
      otas: Array,
      activeOtas: Array,
      carClass: Object,
      day: String,
      isApplied: Boolean,
      otaData: Object,
      outdated: Boolean
    },

    computed: {
      ...mapState("sharedInventory", ["togglers"]),

      saleStopToggler() {
        return this.togglers[`saleStop${this.carClass.id}`]
      },

      activeOtaIds({ activeOtas }) {
        return map(activeOtas, "id")
      },

      commonSaleStop({ activeOtas }) {
        return every(activeOtas, ota => this.saleStopByOta(ota))
      },

      commonSaleStopClasses({ isCommonChanged, isEverySaleStopped, isSomeSaleStopped }) {
        return {
          highlight: isCommonChanged,
          "purple-filled": isEverySaleStopped,
          "grey-filled": isSomeSaleStopped && !isEverySaleStopped
        }
      },

      indeterminateCommonSaleStop({ activeOtas }) {
        return some(activeOtas, ota => this.saleStopByOta(ota))
      },

      isCommonChanged({ activeOtas }) {
        return some(activeOtas, ota => this.isChanged(ota))
      },

      isEverySaleStopped({ isApplied, activeOtas, saleStop, isCommonChanged }) {
        return isApplied && every(activeOtas, ota => saleStop[ota.id]) && !isCommonChanged
      },

      isSomeSaleStopped({ isApplied, activeOtas, saleStop, isCommonChanged }) {
        return isApplied && some(activeOtas, ota => saleStop[ota.id]) && !isCommonChanged
      }
    },

    methods: {
      isEmpty,

      isOtaFilled(ota) {
        return this.isApplied && this.saleStop[ota.id]
      },

      isChanged(ota) {
        if (isEmpty(this.saleStop)) {
          return this.changedSaleStop[ota.id] === true
        } else {
          return !isNil(this.changedSaleStop[ota.id]) && this.saleStop[ota.id] !== this.changedSaleStop[ota.id]
        }
      },

      isOtaActive(ota) {
        return this.activeOtaIds.includes(ota.id)
      },

      isOtaInactive(ota) {
        return this.otaData[ota.id]?.inactive_ota
      },

      isMatchingsWrong(ota) {
        return this.otaData[ota.id]?.matchings_wrong
      },

      saleStopByOta(ota) {
        if (isNil(this.changedSaleStop[ota.id])) {
          return this.saleStop[ota.id] || false
        } else {
          return this.changedSaleStop[ota.id]
        }
      },

      handleChange(ota, value) {
        this.$emit("change", { ...this.saleStop, ...this.changedSaleStop, [ota.id]: value })
      },

      handleChangeCommon(value) {
        const changedSaleStop = reduce(
          this.activeOtas,
          (obj, ota) => {
            obj[ota.id] = value
            return obj
          },
          {}
        )
        this.$emit("change", changedSaleStop)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .sale-stop
    ::v-deep
      .app-checkbox
        &.disabled
          .app-checkbox-icon
            border-color: $default-gray !important
        &-icon
          margin: 0
        &.highlight
          .app-checkbox-icon
            background: #f3f4f6
            border-color: $default-gray-medium

            svg, .svg-fillable
              fill: $default-gray !important

      .grey-filled
        .app-checkbox-icon
          background-color: $default-gray
          border-color: $default-gray-medium
        .svg-fillable
          fill: $default-white

      .purple-filled
        .app-checkbox-icon
          background-color: $default-purple
        .svg-fillable
          fill: $default-white

    .common
      +cell

    .otas
      .ota
        +cell

        &.ota-inactive
          background-color: $default-gray-light
          color: $default-gray

        &.ota-inactive,
        &.disabled
          ::v-deep
            .app-checkbox-icon
              border-color: $default-red
</style>
