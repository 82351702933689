import {
  eachDayOfInterval,
  format,
  add as dateAdd,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday
} from "date-fns"
import { forEach, map, transform, isEmpty, difference, concat, uniq, reject } from "lodash-es"
import { isHoliday as isJapaneseHoliday } from "japanese-holidays"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"

const WEEKDAYS_FUNCTIONS = {
  monday: isMonday,
  tuesday: isTuesday,
  wednesday: isWednesday,
  thursday: isThursday,
  friday: isFriday,
  saturday: isSaturday,
  sunday: isSunday
}

const isHoliday = date => {
  const holidayName = isJapaneseHoliday(date)

  return !isEmpty(holidayName)
}

const isTomorrowHoliday = date => {
  const tomorrow = dateAdd(date, { days: 1 })
  return isHoliday(tomorrow)
}

export const getCheckWeekdayFunctions = weekdays => {
  const functions = []

  forEach(weekdays, (value, weekday) => {
    if (value) functions.push(WEEKDAYS_FUNCTIONS[weekday])
  })

  return functions
}

export const getAllowedFormattedDates = ([start, end], weekdays, holidayOptions) => {
  const dateRange = eachDayOfInterval({ start, end })

  return formattedDates(allowedDates(dateRange, weekdays, holidayOptions))
}

const formattedDates = allowedDates => {
  return map(allowedDates, dateItem => format(dateItem, DATE_FNS_DATE_FORMAT))
}

const allowedDates = (dateRange, weekdays, { holiday, tomorrow_holiday }) => {
  const { holidayDates, tomorrowHolidayDates } = getDates(dateRange, weekdays, holiday, tomorrow_holiday)

  if (holiday && !tomorrow_holiday) return holidayDates
  if (!holiday && tomorrow_holiday) return tomorrowHolidayDates
  if (holiday && tomorrow_holiday) return concat(holidayDates, tomorrowHolidayDates)

  return notSelectedHolidayOptions(holidayDates, tomorrowHolidayDates)
}

const getDates = (dateRange, weekdays, holiday, tomorrow_holiday) => {
  const dates = {}
  const datesByWeekdays = getDatesByWeekdays(dateRange, weekdays)
  const datesByHolidays = getDatesByHolidays(dateRange)
  const datesByTomorrowHolidays = getDatesByTomorrowHolidays(dateRange)

  const rejectedDatesByWeekdays = getRejectedDatesByWeekdays(datesByWeekdays, holiday, tomorrow_holiday)

  if (holiday || (!holiday && !tomorrow_holiday)) {
    dates.holidayDates = datesConsideringHolidayOption(holiday, weekdays, rejectedDatesByWeekdays, datesByHolidays)
  }

  if (tomorrow_holiday || (!holiday && !tomorrow_holiday)) {
    dates.tomorrowHolidayDates = datesConsideringHolidayOption(
      tomorrow_holiday,
      weekdays,
      rejectedDatesByWeekdays,
      datesByTomorrowHolidays
    )
  }

  return dates
}

// reject holidays/tomorrowHolidays from datesByWeekdays by holiday/tomorrow_holiday options
const getRejectedDatesByWeekdays = (datesByWeekdays, holiday, tomorrow_holiday) => {
  let afterHolidayCheck, afterTomorrowHolidayCheck

  if (holiday) {
    afterHolidayCheck = datesByWeekdays
  } else {
    // remove all holidays from datesByWeekdays
    afterHolidayCheck = reject(datesByWeekdays, dateItem => isHoliday(dateItem))
  }

  if (tomorrow_holiday) {
    afterTomorrowHolidayCheck = afterHolidayCheck
  } else {
    // remove all tomorrowHolidays from afterHolidayCheck
    afterTomorrowHolidayCheck = reject(afterHolidayCheck, dateItem => isTomorrowHoliday(dateItem))
  }

  return afterTomorrowHolidayCheck
}

// remove all dates where selected weekdays are holiday or date before holiday
const notSelectedHolidayOptions = (holidayDates, tomorrowHolidayDates) => {
  const allDates = uniq(concat(holidayDates, tomorrowHolidayDates))

  // take all dates which no need to select
  // unnecessary dates which removed from holidayDates but exists in tomorrowHolidayDates
  const unnecessaryHolidays = difference(tomorrowHolidayDates, holidayDates)
  // unnecessary dates which removed from tomorrowHolidayDates but exists in holidayDates
  const unnecessaryTomorrowHolidays = difference(holidayDates, tomorrowHolidayDates)
  const unnecessaryDates = concat(unnecessaryHolidays, unnecessaryTomorrowHolidays)

  // remove all unecessary dates from allDates array
  return difference(allDates, unnecessaryDates)
}

const getDatesByWeekdays = (dateRange, weekdays) => {
  return transform(
    dateRange,
    (array, dateItem) => {
      const dateIncluding = map(weekdays, weekday => weekday(dateItem)).includes(true)
      if (dateIncluding) {
        array.push(dateItem)
      }
      return array
    },
    []
  )
}

const getDatesByHolidays = dateRange => {
  return transform(
    dateRange,
    (array, dateItem) => {
      if (isHoliday(dateItem)) array.push(dateItem)
      return array
    },
    []
  )
}

const getDatesByTomorrowHolidays = dateRange => {
  return transform(
    dateRange,
    (array, dateItem) => {
      if (isTomorrowHoliday(dateItem) && !isHoliday(dateItem)) array.push(dateItem)
      return array
    },
    []
  )
}

// if `holiday` == true and `tomorrow_holiday` == false
// then need to return all dates by selected weekdays
// except dates which is tomorrow holiday
// and all dates which are holidays
// otherwise return all dates by selected weekdays
const datesConsideringHolidayOption = (holidayOption, weekdays, datesByWeekdays, datesByHolidayOption) => {
  if (holidayOption) {
    // need to return all (holiday/tomorrowHoliday) dates when weekdays is not selected
    if (isEmpty(weekdays)) {
      return datesByHolidayOption
    } else {
      return uniq(concat(datesByWeekdays, datesByHolidayOption))
    }
  } else {
    return datesByWeekdays
  }
}
