import { mapGetters } from "vuex"

import { map } from "lodash-es"

export default {
  computed: {
    ...mapGetters(["translatedOtaList"]),

    otaList({ translatedOtaList, otaIdsWithAccounts }) {
      return translatedOtaList.filter(({ id }) => otaIdsWithAccounts.includes(id))
    },

    otaIdsWithAccounts({ otaAccounts }) {
      return map(otaAccounts, "ota.id")
    }
  },

  methods: {
    selectedOtas(otaIds) {
      if (!otaIds || otaIds.length === 0) return []

      return this.otaList.filter(({ id }) => otaIds.includes(id))
    }
  }
}
