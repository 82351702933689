import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { add as dateAdd, format as dateFormat } from "date-fns"
import { currentDateInTimeZone } from "@/helpers/common"
import { map } from "lodash-es"

import mapActions from "./actions"
import mapMutations from "./mutations"

const today = currentDateInTimeZone()

const BASE_URI = "/shared_inventory"

export const mapFiltersFromState = ({ filters: { inventoryGroup, dates }, carClassesForPage }) => ({
  inventory_group_id: inventoryGroup.id,
  car_class_ids: map(carClassesForPage, "id"),
  dates: dates.map(date => dateFormat(date, DATE_FNS_DATE_FORMAT))
})

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["update"],
  withFilters: {
    inventoryGroup: {},
    carClasses: [],
    dates: [today, dateAdd(today, { months: 2 })],
    monthPickerEnabled: true
  },
  withPagination: true
})

store.mergeState({
  items: {},
  processingInventoryGroupIds: [],
  carClassesForPage: [],
  togglers: {}
})

const { FETCH_ITEMS, UPDATE_ITEMS, UPDATE_PAGINATION_DATA, RESEND_INVENTORY } = mapActions({ baseURI: BASE_URI })
store.mergeActions({ UPDATE_PAGINATION_DATA, UPDATE_ITEMS })
store.mergeActions({ FETCH_ITEMS, RESEND_INVENTORY }, withLoading)

store.mergeMutations(mapMutations())

export default store
