<template lang="pug">
  .calendar-body
    .car-class-row
      .car-class-definitions-wrapper
        CarClassDefinition(
          v-for="carClass in carClasses"
          :key="carClass.id"
          :car-class="carClass"
          :otas="otas"
          :ota-data="otaData(carClass.id)"
        )
      ScrollSync.car-class-row-wrapper(horizontal)
        CarClassRow(
          v-for="carClass in carClasses"
          :formatted-grouped-date-range="formattedGroupedDateRange"
          :car-class="carClass"
          :otas="otas"
          :inventories="inventoryData(carClass.id)"
          :ota-data="otaData(carClass.id)"
          :changed-inventories="changedInventories[carClass.id] || {}"
          :inventory-calculations="inventoryCalculations[carClass.id] || {}"
          :current-date="currentDate"
          @change="handleChangeInventory(carClass, $event)"
        )
</template>

<script>
  // misс
  import { mapValues, head, last } from "lodash-es"
  import { getFormattedDateRange } from "@/helpers/dates"

  // components
  import CarClassDefinition from "./CarClassDefinition"
  import CarClassRow from "./CarClassRow"

  export default {
    components: {
      CarClassDefinition,
      CarClassRow
    },

    props: {
      sharedInventories: {
        type: Object,
        default: () => new Object()
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      groupedDateRange: {
        type: Object,
        default: () => new Object()
      },
      changedInventories: {
        type: Object,
        default: () => new Object()
      },
      inventoryCalculations: {
        type: Object,
        default: () => new Object()
      },
      currentDate: {
        type: Date,
        default: () => new Date()
      }
    },

    computed: {
      otas() {
        return this.$store.getters.translatedOtaList
      },

      formattedGroupedDateRange({ groupedDateRange }) {
        return mapValues(groupedDateRange, dates => {
          return getFormattedDateRange(head(dates), last(dates))
        })
      }
    },

    methods: {
      inventoryData(carClassId) {
        return this.sharedInventories[carClassId]?.inventory_data || {}
      },

      otaData(carClassId) {
        return this.sharedInventories[carClassId]?.ota_data || {}
      },

      handleChangeInventory(carClass, { day, inventory }) {
        const existingChanges = this.changedInventories[carClass.id] || {}

        const updatedChanges = {
          ...this.changedInventories,
          [carClass.id]: {
            ...existingChanges,
            [day]: inventory
          }
        }

        this.$emit("change", updatedChanges)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .calendar-body
    .car-class-row
      display: flex
    .car-class-row-wrapper
      overflow-x: auto
</style>
