import StoreItemsModule from "@/config/store/StoreItemsModule"
import extractGetters from "@/config/store/inventory_imports/getters"
const BASE_URI = "/imports"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"]
})

store.mergeGetters(extractGetters())

export default store
