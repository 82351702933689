import StoreItemsModule from "@/config/store/StoreItemsModule"

const BASE_URI = "/exports"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"]
})

export default store
