<template lang="pug">
  .car-class-row
    .months
      .month(v-for="days in formattedGroupedDateRange")
        DayInventory(
          v-for="day in days"
          :key="`${carClass.id}-${day}`"
          :car-class="carClass"
          :day="day"
          :otas="otas"
          :active-otas="activeOtas"
          :ota-data="otaData"
          :inventory="inventories[day]"
          :changed-inventory="changedInventories[day]"
          :inventory-calculations="inventoryCalculations[day]"
          :outdated="day < formattedCurrentDate"
          @change="handleChangeInventory(day, $event)"
        )
</template>

<script>
  // misc
  import { includes, filter } from "lodash-es"
  import { getFormattedDate } from "@/helpers/dates"

  // components
  import DayInventory from "./DayInventory"

  export default {
    components: {
      DayInventory
    },

    props: {
      formattedGroupedDateRange: {
        type: Object,
        default: () => new Object()
      },
      carClass: Object,
      otas: Array,
      inventories: {
        type: Object,
        default: () => new Object()
      },
      otaData: {
        type: Object,
        default: () => new Object()
      },
      changedInventories: {
        type: Object,
        default: () => new Object()
      },
      inventoryCalculations: {
        type: Object,
        default: () => new Object()
      },
      currentDate: {
        type: Date,
        default: () => new Date()
      }
    },

    computed: {
      activeOtas({ otas, otaData }) {
        return filter(otas, ota => {
          const { matchings_wrong, inactive_ota } = otaData[ota.id] || {}
          return !matchings_wrong && !inactive_ota
        })
      },

      formattedCurrentDate() {
        return getFormattedDate(this.currentDate)
      }
    },

    methods: {
      includes,

      handleChangeInventory(day, inventory) {
        this.$emit("change", { day, inventory })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .car-class-row
    border-bottom: 3px solid $default-purple-light-line
    width: fit-content

    .months
      display: flex

      .month
        display: flex
        border-right: 1px solid $border-element-color
</style>
