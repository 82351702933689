import { reduce, forEach, get, isNil, transform, isEmpty } from "lodash-es"
import {
  getCheckWeekdayFunctions,
  getAllowedFormattedDates
} from "@/pages/InventoryManagement/SetInventoryForLongPeriod/getLongPeriodChangedInventories/dates"

const loadedSaleStops = (inventory, date) => {
  return reduce(
    inventory.ota_data,
    (obj, { ota_id }) => {
      obj[ota_id] = get(inventory, `inventory_data.${date}.sale_stop.${ota_id}`)

      return obj
    },
    {}
  )
}

const buildSaleStop = (inventory, saleSettings, date) => {
  return reduce(
    inventory.ota_data,
    (obj, { ota_id }) => {
      const { add_sale_stop, delete_sale_stop } = saleSettings[ota_id] || {}
      const existing = get(inventory, `inventory_data.${date}.sale_stop.${ota_id}`)

      if (add_sale_stop && !existing) {
        obj[ota_id] = true
      } else if (delete_sale_stop && existing) {
        obj[ota_id] = false
      }

      return obj
    },
    {}
  )
}

const loadedSharedInventoryLimit = (date, inventory) => {
  return get(inventory, `inventory_data.${date}.shared_inventory_limit`)
}

const extractSharedInventoryLimit = (date, { shared_inventory_limit }, inventory) => {
  if (!isNil(shared_inventory_limit) && loadedSharedInventoryLimit(date, inventory) !== shared_inventory_limit) {
    return shared_inventory_limit
  }
}

const extractSaleStop = (date, setting, inventory) => {
  const filteredSaleSettings = transform(
    setting.sale_settings,
    (obj, saleSetting, otaID) => {
      const { inactive_ota, matchings_wrong } = inventory.ota_data[otaID]
      if (!inactive_ota && !matchings_wrong) {
        obj[otaID] = saleSetting
      }
      return obj
    },
    {}
  )

  return buildSaleStop(inventory, filteredSaleSettings, date)
}

const buildInventoryObject = (date, setting, inventory) => {
  const inventoryObject = {}

  const sharedInventoryLimit = extractSharedInventoryLimit(date, setting, inventory)
  const saleStop = extractSaleStop(date, setting, inventory)
  const isInventoryExist = sharedInventoryLimit !== undefined

  if (isInventoryExist || !isEmpty(saleStop)) {
    inventoryObject.shared_inventory_limit = isInventoryExist
      ? sharedInventoryLimit
      : loadedSharedInventoryLimit(date, inventory)

    inventoryObject.sale_stop = { ...loadedSaleStops(inventory, date), ...saleStop }
  }

  return inventoryObject
}

export default ({ settings, weekdays, holidayOptions, date, carClasses, inventories }) => {
  const checkWeekdayFunctions = getCheckWeekdayFunctions(weekdays)
  const allowedDates = getAllowedFormattedDates(date, checkWeekdayFunctions, holidayOptions)

  return reduce(
    carClasses,
    (obj, { id, order }) => {
      forEach(allowedDates, date => {
        const inventoryObject = buildInventoryObject(date, settings[order], inventories[id])
        if (!isEmpty(inventoryObject)) {
          obj[id] ||= {}
          obj[id][date] = inventoryObject
        }
      })

      return obj
    },
    {}
  )
}
