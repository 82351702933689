<template lang="pug">
  .ota-definition(:class="{ 'ota-inactive': isInactive }")
    .name {{ ota.name }}
    AppTooltip(
      v-if="isInactive || isMatchingsWrong"
      :title="message"
      type="danger"
      icon="exclamation-triangle"
    )
</template>

<script>
  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      ota: Object,
      otaData: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: {
      message({ isInactive, isMatchingsWrong }) {
        if (isInactive) return this.$t("inventory_management.ota_account_disabled_tooltip")
        if (isMatchingsWrong) return this.$t("inventory_management.shared_inventory.improper_matching_tooltip")

        return undefined
      },

      isInactive({ otaData }) {
        return otaData.inactive_ota
      },

      isMatchingsWrong({ otaData }) {
        return otaData.matchings_wrong
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .ota-definition
    align-items: center
    border-bottom: 1px solid $border-element-color
    display: flex
    font-size: 0.8rem
    height: $row-height
    margin-left: 20px
    padding-left: 10px
    text-transform: uppercase

    .name
      font-weight: bold

    &.ota-inactive
      background-color: $default-gray-light
      color: $default-gray
</style>
