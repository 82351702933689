<template lang="pug">
  .shared-inventory-management.container-fluid
    AppOverlayLoader.loader(:state="inventoriesLoading")
    TopBar.top-bar_sticky(
      v-show="isFiltersVisible"
      :filters="filters"
      :inventory-groups="inventoryGroups"
      :inventory-groups-loading="inventoryGroupsLoading"
      :car-classes="carClasses"
      :car-classes-loading="carClassesLoading"
      :is-saving-disabled="isSavingDisabled"
      :is-changed-inventories="isChangedInventories"
      :is-inventory-sync-in-progress="isInventorySyncInProgress"
      :is-period-and-button-filters-visible="isPeriodAndButtonFiltersVisible"
      :in-progress-reference-item-ids="inProgressReferenceItemIds"
      :is-close-dropdown-on-click-outside="isCloseDropdownOnClickOutside"
      @change:inventory-group="handleChangeInventoryGroup"
      @change:car-classes="handleChangeCarClasses"
      @change:dates="handleChangeDates"
      @change:month-picker-enabled="handleMonthPickerEnabled"
      @save="handleSaveChanges"
      @closed:car-classes="handleCloseCarClassesDropdown"
      @reset-changed-inventories="handleResetInventories"
      @resend-inventory="resendInventory"
      :processing-inventory-group-ids="processingInventoryGroupIds"
      :class="{ visible: isPeriodAndButtonFiltersVisible }"
    )
    CalendarHeader.sticky(
      :class="{ visible: isFiltersVisible && isPeriodAndButtonFiltersVisible, sync_notification: isInventorySyncInProgress }"
      ref="calendarHeader"
      :grouped-date-range="calendarHeaderGroupedDateRange"
      :editable="isValidFilters"
      :sale-stop-days="saleStopDays"
      :is-inventories-present="isInventoriesPresent"
      :is-filters-visible="isFiltersVisible"
      :car-classes="filters.carClasses"
      :is-changed-inventories="isReallyChangedInventories"
      :inventory-groups="inventoryGroups"
      :current-date="currentDate.value"
      @change-sale-stop-days="changeSaleStopDay"
      @toggle-filter-visibility="toggleFilterVisibility"
    )
    CalendarBody(
      v-if="isInventoriesPresent"
      :shared-inventories="inventories"
      :car-classes="carClassesForPage"
      :grouped-date-range="groupedDateRange"
      :changed-inventories="changedInventories"
      :inventory-calculations="inventoryCalculations"
      :current-date="currentDate.value"
      @change="handleChangeInventories"
    )
</template>

<script>
  // store modules
  import sharedInventoryModule from "@/config/store/shared_inventory_management"
  import inventoryGroupsModule from "@/config/store/matching/inventory_group"
  import carClassMatchingModule from "@/config/store/shared_inventory_management/car_classes"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"
  import withWebSocket from "@/mixins/withWebSocket"
  import withInventorySyncMethods from "@/mixins/inventories/withInventorySyncMethods"
  import withSaleStopDaysMethods from "@/mixins/inventories/withSaleStopDaysMethods"
  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  // misc
  import { appDebounce } from "@/helpers/common"
  import { isEmpty, flatMap, forEach, get, groupBy, mapValues, some, sumBy, transform, isEqual } from "lodash-es"
  import { getDateRange, getFormattedDateRange, startOfCurrentDateInTimeZone } from "@/helpers/dates"
  import { format as dateFormat, startOfDay } from "date-fns"
  import inventoryCalculations from "./inventoryCalculations"
  import { bus } from "@/config"
  import getLongPeriodChangedInventories from "./SetForLongPeriod/changedInventories"

  // components
  import CalendarHeader from "./CalendarHeader"
  import CalendarBody from "./CalendarBody"

  const sharedInventoryMixin = withStoreModule(sharedInventoryModule, {
    name: "sharedInventory",
    readers: {
      inventories: "items",
      processingInventoryGroupIds: "processingInventoryGroupIds",
      inventoriesLoading: "loading",
      carClassesForPage: "carClassesForPage",
      filters: "filters"
    },
    mutations: {
      updateProcessingInventoryGroup: "UPDATE_PROCESSING_INVENTORY_GROUP",
      setInventories: "SET_ITEMS",
      setFilters: "SET_FILTERS"
    },
    actions: {
      resendInventory: "RESEND_INVENTORY",
      fetchSharedInventories: "FETCH_ITEMS",
      saveChanges: "UPDATE_ITEMS"
    }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "inventoryGroupsManagement",
    readers: { inventoryGroups: "items", inventoryGroupsLoading: "loading" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" },
    mutations: { setSorting: "SET_SORTING" }
  })

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    name: "sharedCarClassMatching",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClassesAction: "FETCH_ITEMS" }
  })

  const DISTANCE_LENGTH = 75

  export default {
    components: {
      CalendarHeader,
      CalendarBody,
      TopBar: () => import("./TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      sharedInventoryMixin,
      inventoryGroupsMixin,
      carClassMatchingMixin,
      withConfirmation,
      withWebSocket,
      withInventorySyncMethods({ sharedInventory: true }),
      withSaleStopDaysMethods,
      withToyotaOrganizationsMountedOrder
    ],

    beforeRouteLeave(to, from, next) {
      this.beforeRouteLeaveHandler({
        to,
        next,
        isChanges: this.isReallyChangedInventories,
        exitHandler: () => {
          this.handleResetInventories()
        }
      })
    },

    async mounted() {
      this.setInventories({})
      this.fetchInventoryGroups({ pagination: { _disabled: true }, matched_only: true, with_shops_only: true })

      // refresh car classes list if inventoryGroup is selected
      // for cases when go to Shared inventory namagement page after change any matchings
      if (!isEmpty(this.filters.inventoryGroup)) await this.fetchCarClassesAndUpdateFilters()
      if (this.isValidFilters) this.fetchInventories()

      bus.on("set-inventory-long-period", this.handleSetInventoryLongPeriod)
      bus.on("close-modal", this.setFailedInventories)

      this.currentDate.intervalID = window.setInterval(() => {
        this.currentDate.value = startOfDay(startOfCurrentDateInTimeZone())
      }, 1000)

      this.filtersVisibleIntervalID = setInterval(() => {
        const calendarHeaderElement = this.$refs.calendarHeader?.$el
        if (calendarHeaderElement) {
          const rect = calendarHeaderElement.getBoundingClientRect()
          const distance = rect.top

          if (distance == DISTANCE_LENGTH && this.isManualFiltersVisible && this.isValidFilters) {
            this.isFiltersVisible = false
            this.isManualFiltersVisible = false
          }

          if (distance > DISTANCE_LENGTH && this.isManualFiltersVisible) {
            this.isFiltersVisible = true
          }
        }
      }, 300)
    },

    beforeDestroy() {
      bus.off("set-inventory-long-period", this.handleSetInventoryLongPeriod)
      bus.off("close-modal", this.setFailedInventories)
      window.clearInterval(this.currentDate.intervalID)
      window.clearInterval(this.filtersVisibleIntervalID)
    },

    watch: {
      isReallyChangedInventories(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    data() {
      return {
        changedInventories: {},
        isFiltersVisible: true,
        isManualFiltersVisible: true,
        isPeriodAndButtonFiltersVisible: false,
        carClassesCachedFilter: [],
        isCloseDropdownOnClickOutside: true,
        currentDate: {
          value: null,
          intervalID: null
        }
      }
    },

    computed: {
      inventoryCalculations,

      hasProcessingInventoryGroups({ filters: { inventoryGroup } }) {
        return !isEmpty(inventoryGroup) && this.processingInventoryGroupIds.includes(inventoryGroup.id)
      },

      dateRange({ filters }) {
        return getDateRange(...filters.dates)
      },

      formattedDateRange({ filters }) {
        return getFormattedDateRange(...filters.dates)
      },

      groupedDateRange() {
        return groupBy(this.dateRange, date => dateFormat(date, "yyyy-MM"))
      },

      isValidFilters({ filters }) {
        return !isEmpty(filters.inventoryGroup) && !isEmpty(filters.carClasses)
      },

      isChangedInventories({ changedInventories }) {
        return !isEmpty(changedInventories)
      },

      isReallyChangedInventories({ reallyChangedInventories }) {
        return !isEmpty(reallyChangedInventories)
      },

      reallyChangedInventories({ changedInventories }) {
        return transform(
          changedInventories,
          (obj, changedByDates, carClassID) => {
            forEach(changedByDates, (changedInventory, date) => {
              const initialInventory = get(this.inventories, `${carClassID}.inventory_data.${date}`)
              if (this.isInventoryChanged(changedInventory, initialInventory, carClassID, false)) {
                obj[carClassID] = { ...obj[carClassID], [date]: changedInventory }
              }
            })
          },
          {}
        )
      },

      groupedTotalBooked({ inventories }) {
        return mapValues(inventories, ({ inventory_data }) => {
          return mapValues(inventory_data, ({ booked_inventory }) => {
            return sumBy(flatMap(booked_inventory), "value")
          })
        })
      },

      isSavingDisabled({ hasProcessingInventoryGroups, isChangedInventories, inventoryCalculations }) {
        return (
          hasProcessingInventoryGroups ||
          !isChangedInventories ||
          some(inventoryCalculations, calculationsByDay => {
            return some(calculationsByDay, ({ isInvalid, isChanged }) => isInvalid && isChanged)
          })
        )
      },

      selectedCarClasses({ filters }) {
        return filters.carClasses
      },

      isInventoriesPresent() {
        return !isEmpty(this.inventories)
      }
    },

    methods: {
      async fetchCarClassesAndUpdateFilters() {
        await this.fetchCarClassesAction({ inventory_group_id: this.filters.inventoryGroup.id }).then(() => {
          const activeCarClasses = this.carClasses.map(({ id }) => id)

          if (!isEmpty(this.filters.carClasses)) {
            const selectedCarClasses = this.filters.carClasses.filter(({ id }) => activeCarClasses.includes(id))

            this.setFilters({ carClasses: selectedCarClasses })
          }
        })
      },

      debouncedFetchSharedInventories: appDebounce(function() {
        this.fetchInventories()
      }, 100),

      fetchInventories(resetInventories = false) {
        this.fetchSharedInventories().then(() => {
          if (resetInventories) {
            this.handleResetInventories()
          }
          this.setFailedInventories()
        })
      },

      setFailedInventories() {
        forEach(this.inventories, (changedByDates, carClassID) => {
          forEach(changedByDates.inventory_data, (changedInventory, date) => {
            if (changedInventory.export_is_notified) {
              if (isEmpty(this.changedInventories[carClassID]?.[date])) {
                this.$set(this.changedInventories, carClassID, {
                  ...this.changedInventories[carClassID],
                  [date]: changedInventory
                })
              }
            }
          })
        })
      },

      fetchCarClasses() {
        this.fetchCarClassesAction({ inventory_group_id: this.filters.inventoryGroup.id })
      },

      resetHeaderScroll() {
        this.$refs.calendarHeader.resetHeaderScroll()
      },

      handleChangeInventoryGroup(inventoryGroup) {
        this.onChangesConfirm(() => {
          this.setFilters({ inventoryGroup, carClasses: [] })
          this.resetHeaderScroll()
          this.setInventories({})
          this.fetchImportsAction()
          this.carClassesCachedFilter = []
          this.isFiltersVisible = true
          this.isManualFiltersVisible = true
          this.isPeriodAndButtonFiltersVisible = false

          if (!isEmpty(inventoryGroup)) {
            this.fetchCarClasses()
          }
        })
      },

      handleChangeCarClasses(carClasses) {
        this.onChangesConfirm(() => {
          this.setFilters({ carClasses })
          if (carClasses.length === 0) this.carClassesCachedFilter = []
          if (isEmpty(this.selectedCarClasses)) this.resetHeaderScroll()
        })
      },

      handleCloseCarClassesDropdown() {
        if (this.isValidFilters) {
          if (!isEqual(this.carClassesCachedFilter, this.filters.carClasses)) {
            this.onChangesConfirm(() => {
              this.debouncedFetchSharedInventories()
            })
          }
          this.carClassesCachedFilter = this.filters.carClasses
        } else {
          this.setInventories({})
        }
      },

      handleChangeDates(dates) {
        this.onChangesConfirm(() => {
          this.setFilters({ dates })

          if (this.isValidFilters) this.debouncedFetchSharedInventories()
        })
      },

      handleMonthPickerEnabled(monthPickerEnabled) {
        this.onChangesConfirm(() => {
          this.setFilters({ monthPickerEnabled })

          if (this.isValidFilters) this.debouncedFetchSharedInventories()
        })
      },

      handleResetInventories() {
        this.changedInventories = {}
      },

      handleChangeInventories(changedInventories) {
        this.changedInventories = transform(
          changedInventories,
          (obj, changedByDates, carClassID) => {
            forEach(changedByDates, (changedInventory, date) => {
              const initialInventory = get(this.inventories, `${carClassID}.inventory_data.${date}`)

              if (this.isInventoryChanged(changedInventory, initialInventory, carClassID)) {
                obj[carClassID] = { ...obj[carClassID], [date]: changedInventory }
              }
            })
          },
          {}
        )
        this.updateHeaderSaleStopDays(changedInventories)
      },

      isInventoryChanged(changedInventory, initialInventory, carClassID, withNotified = true) {
        // when initialInventory is empty then there is no inventory data before the change
        // so inventory was changed exact
        if (isEmpty(initialInventory)) return true

        const isChanged =
          this.isSaleStopChanged(changedInventory, initialInventory, carClassID) ||
          changedInventory.shared_inventory_limit !== initialInventory.shared_inventory_limit

        return withNotified ? isChanged || changedInventory.export_is_notified : isChanged
      },

      isSaleStopChanged(changedInventory, initialInventory, carClassID) {
        const isInitialChanged = some(initialInventory.sale_stop, (initialSaleStop, otaID) => {
          return (
            initialSaleStop !== changedInventory.sale_stop?.[otaID] &&
            !!changedInventory.sale_stop &&
            this.activeOtaIdsByCarClassId[carClassID].includes(Number(otaID))
          )
        })
        return isInitialChanged
      },

      onChangesConfirm(callback) {
        this.isCloseDropdownOnClickOutside = false
        this.$conditionalConfirm({
          useConfirm: this.isReallyChangedInventories,
          handler: () => {
            this.changedInventories = {}
            this.saleStopDays = {}
            callback()
            this.isCloseDropdownOnClickOutside = true
          },
          cancelHandler: () => {
            this.isCloseDropdownOnClickOutside = true
          }
        })
      },

      async handleSaveChanges() {
        if (!this.isChangedInventories) return

        await this.saveChanges({ changedInventories: this.changedInventories })

        this.handleResetInventories()
      },

      async handleSetInventoryLongPeriod({ filters, settings }) {
        this.handleChangeInventoryGroup(filters.inventoryGroup)
        this.setFilters({ dates: filters.date, carClasses: filters.carClasses, monthPickerEnabled: false })

        await this.fetchSharedInventories()
        this.changedInventories = getLongPeriodChangedInventories({
          settings,
          ...filters,
          inventories: this.inventories
        })
        this.setFailedInventories()
        this.updateHeaderSaleStopDays(this.changedInventories)
      },

      toggleFilterVisibility() {
        this.isFiltersVisible = !this.isFiltersVisible
        this.isPeriodAndButtonFiltersVisible = !this.isPeriodAndButtonFiltersVisible
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .shared-inventory-management
    position: relative

    .top-bar_sticky
      position: sticky
      background-color: #fff
      padding-top: 20px
      z-index: 1

      &.visible
        top: 75px

    .sticky
      position: sticky
      top: 75px
      z-index: 10
      background: $default-white
      max-width: 100%
      width: fit-content

      &.visible
        top: 204px

        &.sync_notification
          top: 238px

      @media screen and (max-width: 1200px)
        top: 311px

    .loader
      z-index: 10000002
</style>
