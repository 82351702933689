<template lang="pug">
  .car-class-definition
    .car-class-name(:title="carClass.name") {{ carClass.name }}
    .shared-inventory
      | {{ $t("activerecord.attributes.inventory.shared_inventory") }}
    .booked-inventory(@click="toggle('bookedInventory')")
      | {{ $t("activerecord.attributes.inventory.booked_inventory") }}
      AppChevronIcon.m-r-10(:up="bookedInventoryToggler")
    .otas(v-if="bookedInventoryToggler")
      OtaDefinition(
        v-for="ota in otas"
        :key="ota.id"
        :ota="ota"
        :ota-data="otaData[ota.id]"
      )
    .available-inventory
      | {{ $t("activerecord.attributes.inventory.available_inventory") }}
    .sale-stop(@click="toggle('saleStop')")
      | {{ $t("activerecord.attributes.inventory.sale_stop") }}
      AppChevronIcon.m-r-10(:up="saleStopToggler")
    .otas(v-if="saleStopToggler")
      OtaDefinition(
        v-for="ota in otas"
        :key="ota.id"
        :ota="ota"
        :ota-data="otaData[ota.id]"
      )
</template>

<script>
  // misc
  import { forEach } from "lodash-es"
  import { TOGGLER_NAMES } from "./constants"
  import { mapMutations, mapState } from "vuex"

  // components
  import OtaDefinition from "./OtaDefinition"

  export default {
    components: {
      AppChevronIcon: () => import("@/components/elements/AppChevronIcon"),
      OtaDefinition
    },

    props: {
      carClass: {
        type: Object,
        default: () => new Object()
      },
      otas: {
        type: Array,
        default: () => new Array()
      },
      otaData: {
        type: Object,
        default: () => new Object()
      }
    },

    created() {
      forEach(TOGGLER_NAMES, toggleName => {
        const uniqId = this.getTogglerId(toggleName)

        this.setToggler({
          uniqId,
          toggleState: !!this.togglers[uniqId]
        })
      })
    },

    computed: {
      ...mapState("sharedInventory", ["togglers"]),

      bookedInventoryToggler() {
        return this.togglers[this.getTogglerId("bookedInventory")]
      },

      saleStopToggler() {
        return this.togglers[this.getTogglerId("saleStop")]
      }
    },

    methods: {
      ...mapMutations("sharedInventory", {
        setToggler: "SET_TOGGLERS"
      }),

      toggle(name) {
        const uniqId = this.getTogglerId(name)
        this.setToggler({
          uniqId,
          toggleState: !this.togglers[uniqId]
        })
      },

      getTogglerId(name) {
        return `${name}${this.carClass.id}`
      },

      tooltipMessage() {
        if (this.isOtaInactive) {
          return this.$t("inventory_management.ota_account_disabled_tooltip")
        }

        return this.$t("inventory_management.improper_matching_tooltip")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .car-class-definition
    border-right: 1px solid $default-purple-light-line
    border-bottom: 3px solid $default-purple-light-line
    min-width: 240px
    width: 240px
    padding-top: 20px

    .car-class-name
      color: $default-purple
      font-size: 1.2rem
      font-weight: 700
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      height: $row-height

    .shared-inventory
      border-top: 1px solid $border-element-color

    .shared-inventory,
    .booked-inventory,
    .available-inventory,
    .sale-stop
      align-items: center
      border-bottom: 1px solid $border-element-color
      color: $link-color
      cursor: default
      display: flex
      font-size: 0.8rem
      font-weight: 700
      height: $row-height
      text-transform: uppercase

    .booked-inventory,
    .sale-stop
      cursor: pointer
      justify-content: space-between
</style>
