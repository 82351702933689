import { api } from "@/config"
import { mapFiltersFromState } from "./index"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state }) => {
    const { pagination, sorting } = state

    const params = { filters: mapFiltersFromState(state), pagination, sorting }
    const response = await api.get(baseURI, { params })

    commit("SET_ITEMS", response.data.data)
  },

  UPDATE_ITEMS: async ({ commit, state, getters: { itemsUpdated } }) => {
    const { pagination, sorting } = state

    const params = { filters: mapFiltersFromState(state), pagination, sorting, settings: itemsUpdated }
    const response = await api.put(`${baseURI}`, { ...params })

    commit("SET_ITEMS", response.data.data)
  }
})
