<template lang="pug">
  .day-inventory
    .shared-inventory(:class="{ highlighted: isHighlighted }")
      AppNumberInput(
        :value="sharedInventoryLimit"
        :changed="isSharedInventoryChanged"
        :invalid="isInvalidAvailable"
        :failed="isFailedExport"
        :disabled="outdated"
        @input="handleChangeShareInventory"
      )
    BookedInventory(
      :class="{ highlighted: isHighlighted }"
      :total-booked="inventoryCalculations.totalBooked"
      :booked-inventory="inventory.booked_inventory"
      :otas="otas"
      :car-class="carClass"
      :day="day"
      :ota-data="otaData"
    )
    .available-inventory(
      :class="[{ invalid: isAvailableZero || isInvalidAvailable }, { highlighted: isAvailableHighlighted }]"
    )
      .available
        | {{ inventoryCalculations.availableInventory }}
    SaleStop(
      :is-applied="isApplied"
      :sale-stop="inventory.sale_stop"
      :changed-sale-stop="changedInventory.sale_stop"
      :otas="otas"
      :active-otas="activeOtas"
      :car-class="carClass"
      :day="day"
      :ota-data="otaData"
      :outdated="outdated"
      @change="handleChangeSaleStop"
    )
</template>

<script>
  // components
  import AppNumberInput from "@/components/elements/AppNumberInput"
  import BookedInventory from "./BookedInventory"
  import AvailableInventory from "./AvailableInventory"
  import SaleStop from "./SaleStop"

  // misc
  import { isNil, isEmpty, isNumber } from "lodash-es"

  const DEFAULT_INVENTORY_OBJECT = {
    shared_inventory_limit: 0,
    sale_stop: {}
  }

  export default {
    components: {
      AppNumberInput,
      BookedInventory,
      AvailableInventory,
      SaleStop
    },

    props: {
      inventory: {
        type: Object,
        default: () => new Object()
      },
      day: String,
      otas: Array,
      activeOtas: Array,
      carClass: Object,
      changedInventory: {
        type: Object,
        default: () => new Object()
      },
      inventoryCalculations: {
        type: Object,
        default: () => new Object()
      },
      otaData: {
        type: Object,
        default: () => new Object()
      },
      outdated: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isSharedInventoryChanged({ changedInventory, inventory }) {
        return (
          !isNil(changedInventory.shared_inventory_limit) &&
          changedInventory.shared_inventory_limit !== inventory.shared_inventory_limit
        )
      },

      sharedInventoryLimit({ changedInventory, inventory }) {
        if (changedInventory.shared_inventory_limit === 0) {
          return changedInventory.shared_inventory_limit
        } else {
          return changedInventory.shared_inventory_limit || inventory.shared_inventory_limit
        }
      },

      isAvailableZero({ sharedInventoryLimit, inventoryCalculations }) {
        return isNumber(sharedInventoryLimit) && inventoryCalculations.availableInventory === 0
      },

      isInvalidAvailable({ inventoryCalculations }) {
        return inventoryCalculations.isInvalid
      },

      isFailedExport({ inventory }) {
        return inventory.export_is_notified
      },

      isAvailableHighlighted({ inventoryCalculations, isApplied }) {
        return inventoryCalculations.availableInventory <= 0 && isApplied
      },

      isHighlighted({ inventoryCalculations, isApplied }) {
        return inventoryCalculations.availableInventory < 0 && isApplied
      },

      isApplied() {
        return !isEmpty(this.inventory)
      }
    },

    methods: {
      handleChangeShareInventory(shared_inventory_limit) {
        this.handleChange({ shared_inventory_limit })
      },

      handleChangeSaleStop(sale_stop) {
        this.handleChange({ sale_stop })
      },

      handleChange(changes) {
        const newInventoryObject = {
          ...DEFAULT_INVENTORY_OBJECT,
          ...this.inventory,
          ...this.changedInventory,
          ...changes
        }

        this.$emit("change", newInventoryObject)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .day-inventory
    border-right: 1px solid $border-element-color
    font-size: 0.8rem
    margin-top: 50px
    width: 37px

    &:last-child
      border-right: 0
      margin-left: -1px

    .shared-inventory
      align-items: center
      border-bottom: 1px solid $border-element-color
      border-top: 1px solid $border-element-color
      display: flex
      height: $row-height
      justify-content: center

      ::v-deep
        input
          font-weight: bold

    .available-inventory
      .available
        +cell
        font-weight: bold

      &.invalid
        color: $default-red

  .highlighted
    background-color: transparentize($default-red, 0.9)
    ::v-deep
      color: $default-red
      .field
        color: $default-red
        background-color: $default-white !important
        border-color: $default-red
</style>
