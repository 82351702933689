import StoreItemsModule from "@/config/store/StoreItemsModule"
import extractMutations from "./mutations"
import extractActions from "./actions"

const BASE_URI = "/ota_accounts"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch", "create", "update", "delete"]
})

store.mergeState({
  importsProgress: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions())

export default store
