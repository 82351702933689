import { mapGetters } from "vuex"

export default {
  mounted() {
    if (this.isToyotaOrganization) {
      /** TODO: temporary solution for tasks
        https://www.notion.so/Change-sorting-for-inventory-groups-order-for-toyota-okinawa-organization-ae65481cb90444eb8d53257cf651eae2 
        https://www.notion.so/Change-sorting-for-inventory-groups-order-for-toyota-organizations-66fc0f943a2a4eaa91a2cff2f0949c54        
        https://www.notion.so/Metro-Conductor-Task-Management-b5d87051111a48ecb311882a34aa56aa?p=6e9b8f288c2b430ead69defcf9b3cc5f&pm=s
        */
      this.setInventoryGroupsSorting({ field: "id", direction: "asc" })
    }
  },

  computed: {
    ...mapGetters(["isToyotaOrganization"])
  },

  methods: {
    setInventoryGroupsSorting(order) {
      this.setSorting(order)
    }
  }
}
