<template lang="pug">
  .booked-inventory
    .total {{ totalBooked }}
    .otas(v-if="bookedInventoryToggler")
      .ota(
        v-for="ota in otas"
        :id="`booked-${ota.id}-${carClass.id}-${day}`"
        :class="{ 'ota-inactive': isOtaInactive(ota.id), 'ota-bold': bookedValueByOta(ota) !== '—' }"
      ) {{ bookedValueByOta(ota) }}
</template>

<script>
  // components
  import { BPopover } from "bootstrap-vue"

  // misc
  import { sumBy } from "lodash-es"
  import { mapState } from "vuex"

  export default {
    components: {
      BPopover
    },

    props: {
      totalBooked: {
        type: [Number, String],
        default: "—"
      },
      bookedInventory: {
        type: Object,
        default: () => new Object()
      },
      otas: Array,
      carClass: Object,
      day: String,
      otaData: Object
    },

    computed: {
      ...mapState("sharedInventory", ["togglers"]),

      bookedInventoryToggler() {
        return this.togglers[`bookedInventory${this.carClass.id}`]
      }
    },

    methods: {
      isOtaInactive(otaId) {
        return this.otaData[otaId]?.inactive_ota
      },

      bookedValueByOta(ota) {
        const bookedByOta = this.bookedInventory[ota.id]
        return sumBy(bookedByOta, "value") || "—"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .booked-inventory
    .total
      +cell
      font-weight: bold

    .otas
      .ota
        +cell
        cursor: default

        &:hover
          opacity: 0.7

        &.ota-inactive
          background-color: $default-gray-light
          color: $default-gray

        &.ota-bold
          font-weight: bold
</style>
