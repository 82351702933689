<template lang="pug">
  .sale-stop-settings.references.col-md-3
    AppDropdown(
      :autofocus="false"
      searchable
      only-body
      with-search-icon
      :value="filters.reference"
      :items="references"
      :searchPlaceholder="placeholder"
      @select="changeReference"
      order-direction="keep"
    )
</template>

<script>
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  export default {
    props: {
      references: {
        type: Array,
        default: () => Array
      },
      filters: {
        type: Object,
        default: () => {}
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    computed: {
      placeholder() {
        return isSharedInventoryEnabled()
          ? this.$t("sale_stop_settings.search_group")
          : this.$t("sale_stop_settings.search_shop")
      }
    },

    methods: {
      changeReference(reference) {
        this.$emit("change-reference", reference)
      }
    }
  }
</script>
<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .sale-stop-settings
    padding-left: 0 !important
    border-right: 1px solid $default-gray-light

    ::v-deep
      .app-select,
      .app-select-body
        padding-top: 0
      .app-select-items
        overflow-y: unset
      .app-select-item
        padding: 9px
      .app-select-actions
        margin-bottom: 10px

        input.app-select-search
          height: 34px
</style>
